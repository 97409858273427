
import useI18nAffidaty from '@/composables/I18nAffidatyApi'
import { computed, defineComponent } from 'vue'
import { 
  IonContent, 
  IonPage,
  IonRadioGroup,
  IonListHeader,
  IonItem,
  IonLabel,
  IonRadio,
  IonList
} from "@ionic/vue";
import Footer from "@/components/layout/Footer.vue";
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    Footer,
    IonRadioGroup,
    IonListHeader,
    IonItem,
    IonLabel,
    IonRadio,
    IonList
  },
  setup() {
    const store = useStore()
    const language = computed(() => store.getters["ui/lang"])
    const { t, loadLocaleMessages} = useI18nAffidaty()

    const changeLanguage = (lang: string) => {
			store.commit("ui/triggerLoader", true)
			loadLocaleMessages(lang, `${process.env.VUE_APP_I18N_BASE_URL}/${lang}.json`).then(lang => {
				store.commit("ui/setLanguage", lang)
			})
			setTimeout(() => {
				store.commit("ui/triggerLoader", false)
			}, 1000)
		}
    return{
      t,
      language,
      changeLanguage
    }
  },
})
