<template>
	<ion-page>
		<ion-content class="ion-padding" :fullscreen="true">
      <h2 class="ion-text-center mt-30">
        <ion-text color="primary">
          <b>{{t("Settings")}}</b>
        </ion-text>
      </h2>
      <ion-list>
        <ion-radio-group :value="language" mode="md">
          <ion-list-header>
            <p class="ion-no-margin" color="primary"><b>{{t("Select language")}}</b></p>
          </ion-list-header>
          <!-- <ion-item lines="none" @click="changeLanguage('it-IT')">
            <ion-label>Italiano</ion-label>
            <ion-radio slot="start" color="dark" value="it-IT"></ion-radio>
            <img slot="start" src="/assets/icon/flag-it.png" style="width:20px" class="ion-no-margin mr-10"/>
          </ion-item> -->

          <ion-item lines="none" @click="changeLanguage('en-US')">
            <ion-label>English</ion-label>
            <ion-radio slot="start" color="dark" value="en-US"></ion-radio>
            <img slot="start" src="/assets/icon/flag-en.png" style="width:20px" class="ion-no-margin mr-10"/>
          </ion-item>

        </ion-radio-group>
      </ion-list>
		</ion-content>
		<Footer></Footer>
	</ion-page>
</template>

<script lang="ts">
import useI18nAffidaty from '@/composables/I18nAffidatyApi'
import { computed, defineComponent } from 'vue'
import { 
  IonContent, 
  IonPage,
  IonRadioGroup,
  IonListHeader,
  IonItem,
  IonLabel,
  IonRadio,
  IonList
} from "@ionic/vue";
import Footer from "@/components/layout/Footer.vue";
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    Footer,
    IonRadioGroup,
    IonListHeader,
    IonItem,
    IonLabel,
    IonRadio,
    IonList
  },
  setup() {
    const store = useStore()
    const language = computed(() => store.getters["ui/lang"])
    const { t, loadLocaleMessages} = useI18nAffidaty()

    const changeLanguage = (lang: string) => {
			store.commit("ui/triggerLoader", true)
			loadLocaleMessages(lang, `${process.env.VUE_APP_I18N_BASE_URL}/${lang}.json`).then(lang => {
				store.commit("ui/setLanguage", lang)
			})
			setTimeout(() => {
				store.commit("ui/triggerLoader", false)
			}, 1000)
		}
    return{
      t,
      language,
      changeLanguage
    }
  },
})
</script>
